:root {
    --categories-navigation-height: 40px;
    --categories-navigation-zindex: calc(var(--top-navigation-zindex) - 1);
}

.categories {
    display: flex;
    position: sticky;
    top: var(--top-navigation-height);
    background: $medunka-brown;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.29);
    height: var(--categories-navigation-height);
    z-index: var(--categories-navigation-zindex);

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.categories .nav {
    display: flex;
}

@keyframes fade_in_show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.categories .nav-item--level-1 {
    padding-bottom: 0.8rem;

    &:last-child > div {
        background-color: $medunka-crimson;
        border-radius: 3px;
    }

    > div {
        height: var(--categories-navigation-height)
    }

    > div > a {
        display: flex;
        align-items: center;
        height: 100%;
        padding-block: 0.5rem;
        padding-inline: 1rem;
        color: #ffffff;
        font-size: 0.9rem;
        @include media-breakpoint-down(xl) {
            font-size: 0.85rem;
        }
    }

    > div > ul {
        display: none;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.29);
        flex-wrap: wrap;
        justify-content: flex-start;
        position: absolute;
        max-height: calc(100vh - var(--top-navigation-height) - var(--categories-navigation-height));
        left: 0;
        overflow: auto;
        top: 100%;
        background: #ffffff;
        width: 100%;
        padding: 2.5rem;
        margin: 0;
        z-index: 100;
        font-size: 0.875rem;

        grid-auto-flow: dense;
        gap: 10px;

        --columns: 6;
        @include media-breakpoint-down(xxl) {
            --columns: 5;
        }
        @include media-breakpoint-down(xl) {
            --columns: 4;
        }
        grid-template-columns: repeat(var(--columns), 1fr);

        .nav-item {
            a {
                color: black;
            }

            ul {
                list-style: none;
                padding-left: 0;
            }
        }
    }

    &:hover, &:active {
        > div > ul {
            display: grid;
            animation: fade_in_show 0.5s;
        }
    }
}

.categories .nav-item--level-2 {
    display: flex;
    list-style: none;
    margin-right: 2rem;
    margin-bottom: 1rem;

    .categories__image {
        margin-right: 1rem;
        width: 40px;

        img {
            max-height: 60px;
            max-width: 40px;
            width: auto;
            height: auto;
        }
    }

    > div > a {
        display: block;
        font-weight: bold;
        margin-bottom: 0.2rem;
    }
}
