.cart-progress__wrap {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #ccc;
}

.cart-progress {
    display: flex;
    justify-content: space-between;
    height: 110px;
    max-width: 500px;
    margin-bottom: 20px;

    div.step, a {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    div.step {
        font-size: 0.8em;

        img {
            margin-bottom: 0.5rem;
            max-width: 100%;
            height: 50px;
            object-fit: contain;
        }

        a {
            color: #333333;
        }
    }

    div.active a, div.active span {
        color: #660000;
        font-weight: bold;
    }

    div {
        margin: 0 7px;
        text-align: center;
    }

    div.arrow {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        color: #666666;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

