.nav {
    margin-bottom: 0;
    @include list-unstyled;
}

.nav-item {
    //padding: 0 5px;
    //
    //span {
    //    border-radius: 5px;
    //}
    //
    //&--active, &:active ,&:focus, &:hover {
    //    span {
    //        background-color: #660000;
    //    }
    //}
}
