.footer {
    display: flex;
    flex: 1;
    background-color: $medunka-green;
    color: #ffffff;
    padding: 1.5rem;
    font-size: 0.8125rem;

    .separator {
        margin: 0 20px;
        border-left: 1px solid #ffffff;
    }

    a {
        color: #ffffff;

        &:active,
        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    h4 {
        font-size: $h5-font-size;
    }
}
