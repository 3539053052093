body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content-wrap {
    width: 100%;
    background: #F2E0C9;
    display: flex;
    flex: 1;
    justify-content: center;
}

.content-holder {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xl){
        max-width: 1320px;
    }
}

.all {
    padding: 1.5rem 1rem 1rem;
    gap: 1.5rem;
    display: grid;

    &.main {
        grid-template-areas: "main";
        grid-template-columns: 1fr;
    }

    &.sidebar-main {
        grid-template-areas: "sidebar main";
        grid-template-columns: 1fr minmax(0, 3fr);
    }
}

.main {
    padding: 1.5rem 1rem 1rem;
    background-color: #ffffff;
    grid-area: main;
}
