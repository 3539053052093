.search {
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex: 0 1 500px;
    max-width: 100%;
    height: var(--top-navigation-height);

    @include media-breakpoint-down(lg) {
        flex: 0 0 100%;
        order: 1;
    }

    form {
        width: 100%;
    }

    .form-control {
        border-radius: 0;
    }
}

.search-container {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;

    .dropdown-menu {
        max-height: calc(100vh - var(--top-navigation-height) - 1rem);
        overflow: auto;
    }

    .dropdown-item:not(:last-child) {
        border-bottom: $dropdown-border-width solid $dropdown-border-color;
    }
}

.search-results-container {
    flex: 1 1 auto;
}

.search-input-container {
    position: relative;
    display: flex;
    flex: 1 1 auto;
}

.search-spinner {
    position: absolute;
    top: calc(50% - #{$spinner-width-sm}/2);
    right: calc(#{$spinner-width-sm}/2);
}

.search-item {
    display: flex;
    height: 50px;
    margin-block: 0.2rem;
    color: $body-color;
}

.search-image {
    width: 50px;
    height: 50px;
    display: flex;
    flex: 0 0 50px;
    justify-content: center;
    padding-right: 0.5rem;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.search-item-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
}

.search-item-name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-item-price {
    flex: 1;
    text-align: right;
}
