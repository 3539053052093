.sidebar {
    grid-area: sidebar;
    font-size: 1.1rem;

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.sidebar .nav {
    font-family: $headings-font-family;

    ul {
        border-left: 1px solid #cccccc;
        list-style: none;
        padding: 0.5rem 0 0.5rem 1rem;
        margin-top: 0.5rem;
        display: none;
    }

    .nav-item {
        margin: 0 0 0.4rem;
        width: 100%;
    }

    .nav-item--top {
        > div {
            > a {
                background-color: #006600;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 1rem 0 1rem;
                min-height: 45px;
                font-size: 1.2rem;
                color: #ffffff;

                &:hover {
                    background-color: #660000;
                }
            }
        }

        &.nav-item--active {
            > div {
                > a {
                    background-color: #660000;
                }
            }
        }
    }


    .nav-item:not(.nav-item--top) {
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 5px 10px;
            color: #000000;

            &:active, &:focus, &:hover {
                color: #ffffff;
                background-color: #660000;
                border-radius: 5px;
            }
        }

        div {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                width: 4px;
                top: 50%;
                left: -5px;
                border-top: 1px solid #bbbbbb;
            }

            &:active, &:focus, &:hover {
                &:after {
                    content: none;
                }
            }
        }

        &.nav-item--active {
            > div {
                background-color: #660000;
                border-radius: 5px;

                &:after {
                    content: none;
                }

                a {
                    color: #ffffff;
                }
            }

        }
    }

    .nav-item--active {
        > ul {
            display: block;
        }
    }
}
