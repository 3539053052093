hr {
    margin: 10px 0;
    border: none;
    background: url('./images/dash.png') repeat-x;
    height: 5px;
}

.icon-title.user_details {
    background: url('./images/icons/userDetails.png') no-repeat 0 50%;
}

.icon-title.track {
    background: url('./images/icons/track.png') no-repeat 0 50%;
}

.icon-title.weight {
    background: url('./images/icons/weight.png') no-repeat 0 50%;
}

.icon-title.home {
    background: url('./images/icons/home.png') no-repeat 0 50%;
}

.icon-title.bulb {
    background: url('./images/icons/bulb.png') no-repeat 0 50%;
}

.icon-title.note {
    background: url('./images/icons/note.png') no-repeat 0 50%;
}

.icon-title.done {
    background: url('./images/icons/check.png') no-repeat 0 50%;
}

.icon-title.security {
    background: url('./images/icons/security.png') no-repeat 0 50%;
}

.icon-title.sent {
    background: url('./images/icons/sent.png') no-repeat 0 50%;
}

.icon-title.payment {
    background: url('./images/icons/payment.png') no-repeat 0 50%;
}

.icon-title.time {
    background: url('./images/icons/time.png') no-repeat 0 50%;
}

.icon-title.shipping {
    background: url('./images/icons/shipping.png') no-repeat 0 50%;
}

.icon-title.person {
    background: url('./images/icons/person.png') no-repeat 0 50%;
}

.icon-title.jure {
    background: url('./images/icons/jure.png') no-repeat 0 50%;
}
