:root {
    --top-navigation-height: 50px;
    --top-navigation-zindex: 10;
}

.top-navigation {
    display: flex;
    justify-content: center;
    width: 100%;
    height: var(--top-navigation-height);
    background-color: $medunka-green;
    position: sticky;
    top: 0;
    z-index: var(--top-navigation-zindex);
    font-family: $headings-font-family;

    @include media-breakpoint-down(lg) {
        padding: 0 1rem;
        height: calc(2 * var(--top-navigation-height));
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.29);
    }
}

.top-navigation__content {
    display: flex;
    justify-content: space-between;
    padding-inline: 1rem;
    gap: 0 2rem;
    flex-wrap: wrap;
    line-height: var(--top-navigation-height);
    @include media-breakpoint-down(lg) {
        padding-inline: 0;
        gap: 0 1rem;
    }

    .dropdown-menu {
        line-height: normal;
    }
}

.logo-container {
    display: flex;
}
