.mobile-nav {
    @include media-breakpoint-down(lg) {
        width: 100%;
        left: 0;
        height: calc(100vh);
        overflow: auto;
        top: 100% !important;
        transform: none !important;
    }
}

.mobile-nav {
    .nav-item > div {
        display: block;
        padding: 0.5rem;
        border-bottom: $dropdown-border-width solid $dropdown-border-color;

        > a {
            display: block;
        }
    }
}

