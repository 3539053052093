.products {
    display: flex;
    flex-wrap: wrap;
}

$product-items-in-grid-breakpoints: (
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5
);

.products--featured {
    @each $breakpoint, $items in $product-items-in-grid-breakpoints {
        @include media-breakpoint-up($breakpoint) {
            .product_wrap:nth-child(n + 1) {
                display: flex;
            }
        }
        @include media-breakpoint-up($breakpoint) {
            .product_wrap:nth-child(n + #{$items * 2 + 1}) {
                display: none;
            }
        }
    }
}

.products--newest {
    @each $breakpoint, $items in $product-items-in-grid-breakpoints {
        @include media-breakpoint-up($breakpoint) {
            .product_wrap:nth-child(n + 1) {
                display: flex;
            }
        }
        @include media-breakpoint-up($breakpoint) {
            .product_wrap:nth-child(n + #{$items * 3 + 1}) {
                display: none;
            }
        }
    }
}

.product_wrap {
    display: flex;
    min-height: 280px;
    background-color: #ffffff;
    padding: 1rem 0.5rem 1.5rem;
    margin-left: -1px;
    margin-top: -1px;
    position: relative;
    flex-direction: column;
    width: 100%;
    border: 1px solid #e0e0e0;

    @each $breakpoint, $items in $product-items-in-grid-breakpoints {
        @include media-breakpoint-up($breakpoint) {
            --product-items: #{$items};
        }
    }

    flex: 1 0 calc(100% / var(--product-items));
    max-width: calc(100% / var(--product-items));
}

.unavail.product_wrap {
    opacity: 0.7;
}


.prod_desc {
    font-size: 0.8em;
    line-height: 1.3em;
    padding: 5px;
}

.prod_image {
    flex: 0 0 180px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin-bottom: 0.5rem;
    max-height: 180px;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}

.prod_name {
    flex: 0 0 calc(2rem * var(--bs-body-line-height));
    font-size: 1rem;
}

.product_wrap .prod_price {
    padding: 7px 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
}

.product_wrap .prod_price--normal {
    background-color: $medunka-brown;
    color: #ffffff;
}

.product_wrap .prod_price--sale {
    background-color: $medunka-crimson;
    color: #ffffff;
}

.product_wrap .prod_price--old {
    padding-inline: 4px;
    text-decoration: line-through;
}

.prod_control {
    display: flex;
    position: relative;
    margin-inline: 0.5rem;
    align-items: center;
}

.prod_shop {
    height: 30px;
    margin: 5px 0;
    flex: 1 0 auto;
}

.prod_cart {
    margin-inline: 0.5rem;
}

.product-detail {
    display: grid;
    gap: 1rem;
    grid-template-columns: 2fr minmax(0, 3fr);
    grid-template-rows: auto;
    grid-template-areas:
    "header header"
    "left right";

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-template-areas:
        "header"
        "left"
        "right";
    }
}

.product-detail__header {
    grid-area: header;
}

.product-detail__left {
    grid-area: left;
}

.product-detail__right {
    grid-area: right;
}

#product_page .attribute_name {
    width: 84px;
    margin: 1px;
    padding: 3px 5px;
    display: inline-block;
}

#product_page .attribute_value {
    flex: 1 0 auto;
    width: 170px;
    background-color: #e0e0e0;
    margin: 1px;
    padding: 3px 5px;
    display: inline-block;
}

#product_page .attribute_value img {
    vertical-align: middle;
}

.product_image {
    text-align: center;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}

.product_controls {
    flex: 1 1 auto;
    max-width: 500px;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.product_description pre code {
    color: transparent;
    white-space: normal;
}

$headings-sizes:
    h1 $h2-font-size,
    h2 $h3-font-size,
    h3 $h4-font-size,
    h4 $h5-font-size;

@each $selector, $font-var in $headings-sizes {
    .product_description #{$selector} {
        @include font-size($font-var);
    }
}

#product_page .product_description {
    margin: 20px 0 0;
}

#product_page .product_description table,
#product_page .product_description p {
    margin-bottom: 1rem;
}

#product_page .product_description th,
#product_page .product_description td {
    padding: 3px 5px;
}

#product_page .product_description th {
    background: #006600;
    color: #ffffff;
    text-align: left;
}

#product_page .product_description td {
    background: #fafafa;
    color: #333333;
}

.product_price {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1.2em;
}

.product_price__prices {
    display: flex;
    flex-direction: column;

    .sale {
        background-color: #ed9f0e;
        margin: 0 0 5px;
    }

    span {
        padding: 5px 10px;
        color: #ffffff;
        background-color: #333333;
        border-radius: 5px;
    }
}

.product_price span.small {
    background-color: #f0f0f0;
    color: #666666;
    font-size: 0.9em;
    margin: 0 5px;
    padding: 10px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}
