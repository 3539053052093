.cart {
    .product-name__container {
        display: flex;
    }

    .product-name {
        font-weight: bold;
        padding: 3px 0;

        a {
            color: #333333;
        }
    }

    .product-alert {
        margin-inline: 1rem;
        display: flex;
        align-items: center;
    }
}
