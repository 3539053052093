#product_page .addToCart__succes {
    padding: 6px 0;
    margin: 0 40px;
}

#product_page .addToCart__succes span {
    color: #333333;
    border-radius: 0;
}

.addToCart__succes {
    margin: 0 5px;
    padding-left: 1.5rem;
    height: 100%;
    display: none;
}
