@import "~@fortawesome/fontawesome-free/scss/functions";
@import "~@fortawesome/fontawesome-free/scss/variables";
@import "~@fortawesome/fontawesome-free/scss/mixins";
@import "~@fortawesome/fontawesome-free/scss/core";
@import "~@fortawesome/fontawesome-free/scss/animated";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/solid";

.#{$fa-css-prefix}-angle-right:before { content: fa-content($fa-var-angle-right); }
.#{$fa-css-prefix}-bars:before { content: fa-content($fa-var-bars); }
.#{$fa-css-prefix}-cart-plus:before { content: fa-content($fa-var-cart-plus); }
.#{$fa-css-prefix}-check:before { content: fa-content($fa-var-check); }
.#{$fa-css-prefix}-circle-exclamation:before { content: fa-content($fa-var-circle-exclamation); }
.#{$fa-css-prefix}-circle-notch:before { content: fa-content($fa-var-circle-notch); }
.#{$fa-css-prefix}-facebook-square:before { content: fa-content($fa-var-facebook-square); }
.#{$fa-css-prefix}-lock:before { content: fa-content($fa-var-lock); }
.#{$fa-css-prefix}-phone:before { content: fa-content($fa-var-phone); }
.#{$fa-css-prefix}-search:before { content: fa-content($fa-var-search); }
.#{$fa-css-prefix}-shopping-basket:before { content: fa-content($fa-var-shopping-basket); }
.#{$fa-css-prefix}-store-alt:before { content: fa-content($fa-var-store-alt); }
.#{$fa-css-prefix}-user:before { content: fa-content($fa-var-user); }
