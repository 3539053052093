.logo {
    display: flex;
    flex: 1 1 auto;
    height: var(--top-navigation-height);

    a {
        display: flex;
        align-items: center;
        color: white;
    }

    img {
        height: 2rem;
    }
}

.user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
}

.user_link {
    color: white;
    &:hover, &:focus, &:active {
        color: $accent-color;
    }
}
