.ordering {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px;
    font-size: 1em;
    line-height: 15px;
}

.ordering img {
    vertical-align: middle;
}

.ordering a {
    color: #666666;
    padding: 0 3px;
}

.ordering a:hover {
    color: #006600;
}

.ordering__control {
    margin-bottom: 0.5rem;
}

.ordering__sort {

}

.ordering__product-count {
    margin: 0 0 0 10px;
    padding: 0 5px;

    form {
        input {
            margin: 0 5px 3px;
        }

        select {
            margin: 0 5px;

        }
    }
}
