@font-face {
    font-family: 'Anivers';
    src: url('old/fonts/Anivers-Regular.eot');
    src: url('old/fonts/Anivers-Regular.eot?#iefix') format('embedded-opentype'),
    url('old/fonts/Anivers-Regular.woff2') format('woff2'),
    url('old/fonts/Anivers-Regular.woff') format('woff'),
    url('old/fonts/Anivers-Regular.ttf') format('truetype'),
    url('old/fonts/Anivers-Regular.svg#Anivers-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

a.ul:hover {
    text-decoration: underline;
}

ul.errors {
    color: #660000;
    font-weight: bold;
}

input[type=checkbox] {
    vertical-align: middle;
    margin: 0;
}

input[type=submit], .buttonLink {
    cursor: pointer;
    background-color: #006600;
    border: none;
    padding: 6px;
    color: #ffffff;
    border-radius: 5px;
}

.icon-title {
    padding-left: 40px;
}

input[type=submit]:hover {
    background-color: #660000;
}

.medunka-form__group {
    border: 3px solid #006600 !important;
    padding: 1.5rem 1rem !important;
    margin: 0.5rem 0 !important;
}

fieldset td input[type=text], fieldset td input[type=password] {
    padding: 5px;
    border: 1px solid #cccccc;
    background-color: #f7f7f7;
    color: #333333;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05) inset;
}

fieldset td input[type=text]:focus, fieldset td input[type=password]:focus {
    background-color: #ffffff;
    border: 1px solid #e2c822;
}

table.list td:first-child {
    width: 80%;
    text-align: left;
}

table.list td:first-child img {
    vertical-align: middle;
}

table.list td:last-child {
    width: 20%;
    text-align: right;
}

table.list td.disabled, table.list td.disabled + td:last-child {
    color: #999999;
}

table.list td.disabled img {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

td.label {
    width: 80px;
}

td.tdData {
    width: auto;
}

#moreDetails, #destinationAddress {
    display: none;
}

/*h3 {*/
/*    text-transform: uppercase;*/
/*    font-size:      1.4em;*/
/*    padding:        0 0 0 40px;*/
/*    margin:         30px 0 0;*/
/*    line-height:    32px;*/
/*}*/

/*h4 {*/
/*    font-size:   1.3em;*/
/*    font-weight: bold;*/
/*    padding:     0 0 0 0px;*/
/*    margin:      10px 0 0;*/
/*    line-height: 32px;*/
/*}*/

span.asterisk {
    color: #cc0000;
    margin-left: 0.5rem;
}

div.note textarea {
    border: 1px solid #cccccc;
    padding: 5px;
    line-height: 1.5;
    width: calc(100% - 10px);
    height: 4.6em;
}

#main {
    background-color: #f0f0f0;
}

.main ul {
    padding-bottom: 10px;
    margin: 0 0 0 15px;
    list-style: disc;
}

#cart_page .product {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
}

#cart_page div.image {
    padding: 3px;
    margin: 0 10px 0 0;
    text-align: center;
    border: 1px solid #cccccc;
    width: 56px;
    height: 64px;
}

#cart_page div.image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

#cart_page div.rightHolder {
    flex: 1;
    min-height: 50px;
    border-top: 1px solid #cccccc;
    padding: 3px;
}

#cart_page div.deleteProduct {
    padding: 0 5px;
}

#cart_page div.deleteProduct a {
    line-height: 20px;
}

#cart_page div.deleteProduct img {
    vertical-align: middle;
}

#cart_page div.info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #f1e2d3;
    padding: 3px;
}

#cart_page div.productCount {
    padding: 0 30px 0 0;
}

#cart_page div.productPrice {
    padding: 0 30px;
}

#totalPrice {
    margin: 40px 0 30px;
    font-weight: bold;
    width: 100%;
    font-size: 1.5em;
    text-align: center;
}

#totalPrice .priceDesc {
    color: $accent-color;
    padding: 0 10px 0 0;
}

#totalPrice span.totalPrice {
    color: #006600;
}

#ajax-spinner {
    color: white;
    padding: 0 1rem;
    display: none;
}

.topControls > div {
    margin: 0 10px 10px;
}

.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    font-size: 0.9em;
    border: 1px solid #cccccc;
    margin-bottom: 1rem;
    & > * {
        line-height: 1.5;
    }
}

.breadcrumbs a {
    color: #660000;
}

.breadcrumbs a:hover {
    text-decoration: underline;
}

.breadcrumbs div {
    padding-right: 5px;
}

.breadcrumbs .arrow {
    margin-inline: 6px;
}

.pagination {
    text-align: center;
}

.pagination a {
    padding: 0 2px;
}

.pagination a:hover {
    text-decoration: underline;
}

.pagination a.active {
    font-weight: bold;
    color: #ffffff;
    background-color: #660000;
    padding: 0 4px 1px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

/*icons end*/
div.flash {
    color: black;
    background: #fff9d7;
    border: 1px solid #e2c822;
    padding: 1em;
    margin: 0 0 2em;
}

/*div.flash.error {
    background: #ff8484;
    border-color: #660000;
}*/

a[href^="error:"] {
    background: red;
    color: white;
}

#contact .piece {
    flex: 1 0 auto;
    padding: 0 1rem;
}

#contact td:first-child {
    min-width: 80px;
}
