.cart-widget {
    display: flex;
    flex: 0 0 auto;
}

.cart-widget {
    a {
        color: #ffffff;

        &:active,
        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    .price {
        background-color: $medunka-brown;
        padding: 7px 10px;
        border-radius: 5px;
    }

    .cart_icon {
        font-size: 1.5rem;
    }
}

#snippet-cart-cart {
    > a {
        display: flex;
        align-items: center;

        div {
            margin: 0 0.3rem;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
