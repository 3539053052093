$enable-dark-mode: false;

$body-color: #333333;
$input-bg: white;
$font-family-base: Verdana, Geneva, sans-serif;
$headings-font-family: Anivers, Verdana, Geneva, sans-serif;
$link-color: #006600;
$link-decoration: none;
$accent-color: $medunka-brown;

$input-btn-focus-box-shadow: none;
$dropdown-border-radius: 0;
