.category {

}

.category__title{
    font-size: 1.5rem;
}

.category__subcategories {
    display: grid;
    gap: 8px;
    margin: 1rem 0;

    --category-items: 6;

    @include media-breakpoint-down(xxl) {
        --category-items: 5;
    }
    @include media-breakpoint-down(xl) {
        --category-items: 4;
    }
    @include media-breakpoint-down(lg) {
        --category-items: 3;
    }
    @include media-breakpoint-down(md) {
        --category-items: 2;
    }
    @include media-breakpoint-down(sm) {
        --category-items: 1;
    }

    grid-template-columns: repeat(var(--category-items), 1fr);
}

.category__subcategory {
    align-items: center;
    border: 1px solid #660000;
    border-radius: 3px;
    display: inline-flex;
    height: 50px;
    padding: 5px;
    font-size: 0.8rem;
}

.category__image {
    display: flex;
    width: 50px;
    height: 100%;
    margin-right: 5px;
    justify-content: center;
    flex-shrink: 0;

    img {
        max-width: 100%;
    }
}
